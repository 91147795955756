import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import * as Routes from "../../routes";
import Burger from "../Burger";
import Menu from "../Menu";

const HeaderContainer = styled.header`
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  height: 6rem;
  background: ${({ theme }) => theme.colors.darkGreen};
  margin-bottom: ${({ theme }) => theme.margins.medium};

  @media (min-width: ${(props) => props.theme.width.tablet}) {
    padding: 0 3rem;
  }

  @media (min-width: ${(props) => props.theme.width.desktop}) {
    padding: 0 7rem;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
`;

const LogoWeb = styled.svg`
  display: none;

  @media (min-width: ${(props) => props.theme.width.desktop}) {
    display: block;
  }
`;

const LogoMob = styled.svg`
  display: block;

  @media (min-width: ${(props) => props.theme.width.desktop}) {
    display: none;
  }
`;

const Header = ({ cart }) => {
  const [open, setOpen] = useState(false);

  return (
    <HeaderContainer>
      <NavLink to={Routes.LANDING}>
        <LogoWeb xmlns="http://www.w3.org/2000/svg" width="170" height="88.257">
          <g data-name="Group 117">
            <g data-name="Group 112">
              <g data-name="Group 74">
                <path
                  data-name="Path 36"
                  d="m8.101 61.44-3.336-3.336a.829.829 0 0 0-1.154 0L.242 61.473a.817.817 0 0 0 1.154 1.154l3.369-3.368H3.611l3.336 3.335a.817.817 0 0 0 1.154-1.154Z"
                  fill="#161615"
                />
              </g>
              <g data-name="Group 108">
                <path
                  data-name="Path 35"
                  d="m46.263 26.885 3.335 3.335a.829.829 0 0 0 1.155 0l3.368-3.369a.817.817 0 0 0-1.154-1.154l-3.369 3.369h1.155l-3.336-3.336a.817.817 0 0 0-1.154 1.155Z"
                  fill="#273618"
                />
                <g data-name="Group 106">
                  <g data-name="Group 13">
                    <path
                      data-name="Path 31"
                      d="M4.517 37.088V25.699a27.7 27.7 0 0 1 .625-6.623 22.229 22.229 0 0 1 4.4-8.845 22.337 22.337 0 0 1 39.48 11.435 61.4 61.4 0 0 1 .153 7.043 1.021 1.021 0 0 0 2.041 0c0-2.108.093-4.243-.082-6.345A24.367 24.367 0 0 0 2.48 24.416c-.018 4.223 0 8.446 0 12.669a1.021 1.021 0 0 0 2.041 0Z"
                      fill="#273618"
                    />
                  </g>
                  <g data-name="Group 14">
                    <path
                      data-name="Path 32"
                      d="M2.545 36.327c4.45-.015 35.963-.031 40.413.008.824.007 1.1-.18 1.093-1.051a2.433 2.433 0 0 1 2.448-2.451q2.794-.071 5.589.016a1.123 1.123 0 0 1 .86.638c.084.543-.393.707-.9.705-1.725 0-3.449.006-5.174 0a1.38 1.38 0 0 0-1.5 1.1h6.612c.482 0 .957.075.976.655.022.644-.486.72-.994.721h-5.841c-.412 0-.776-.011-.78.582s.345.6.767.6H52.121c.47.007.924.189.815.69a1.128 1.128 0 0 1-.851.649c-1.89.061-3.782.033-5.672.034h-.9c.013.889.389 1.246 1.322 1.257 1.53.018 3.059 0 4.589.006.222 0 .445.007.667 0 .469-.013.934.145.859.645a1.142 1.142 0 0 1-.827.676 20 20 0 0 1-2.415.034c-1 0-2 .02-3-.006a2.512 2.512 0 0 1-2.677-2.746c.018-.8-.248-.968-.993-.962-4.422.032-36.066.313-40.488.313"
                      fill="#273618"
                    />
                  </g>
                  <g data-name="Group 15">
                    <path
                      data-name="Path 33"
                      d="M3.151 59.55c0 2.108-.092 4.242.083 6.345a24.367 24.367 0 0 0 48.654-2.052c.017-4.223 0-8.446 0-12.67a1.021 1.021 0 0 0-2.041 0v11.386a27.661 27.661 0 0 1-.626 6.623 22.211 22.211 0 0 1-4.4 8.844 22.337 22.337 0 0 1-39.48-11.434 61.417 61.417 0 0 1-.153-7.043 1.021 1.021 0 0 0-2.041 0Z"
                      fill="#273618"
                    />
                  </g>
                  <g data-name="Group 16">
                    <path
                      data-name="Path 34"
                      d="M51.818 51.931c-4.45.014-35.963.031-40.412-.009-.824-.007-1.1.18-1.093 1.052a2.434 2.434 0 0 1-2.449 2.451q-2.793.071-5.588-.016a1.123 1.123 0 0 1-.86-.638c-.084-.544.392-.707.9-.706 1.724 0 3.449-.006 5.173 0a1.379 1.379 0 0 0 1.5-1.1H2.377c-.482 0-.957-.076-.976-.655-.021-.644.487-.72.994-.721h5.841c.413 0 .777.011.781-.581s-.346-.6-.767-.6H2.243c-.47-.006-.925-.189-.815-.689a1.13 1.13 0 0 1 .851-.65c1.889-.06 3.781-.032 5.672-.033h.9c-.012-.889-.389-1.246-1.322-1.257-1.53-.019-3.059-.005-4.589-.006-.223 0-.445-.007-.668 0-.469.012-.933-.145-.858-.646a1.142 1.142 0 0 1 .826-.675 20.006 20.006 0 0 1 2.416-.034c1 0 2-.02 3 .006a2.512 2.512 0 0 1 2.677 2.746c-.018.8.248.967.993.962 4.421-.032 36.065-.313 40.487-.313"
                      fill="#273618"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g data-name="Group 114">
              <text
                data-name="Farmers Without Borders"
                transform="translate(69 9.129)"
                fill="#273618"
                fontSize="16"
                fontFamily="LibreBaskerville-Bold, Libre Baskerville"
                fontWeight="700"
              >
                <tspan x="0" y="16">
                  FARMERS
                </tspan>
                <tspan x="0" y="41">
                  WITHOUT
                </tspan>
                <tspan x="0" y="66">
                  BORDERS
                </tspan>
              </text>
            </g>
          </g>
        </LogoWeb>
        <LogoMob xmlns="http://www.w3.org/2000/svg" width="39.208" height="64">
          <g data-name="Group 98">
            <g data-name="Group 81">
              <g data-name="Logo">
                <g data-name="Group 14">
                  <path
                    data-name="Path 32"
                    d="M1.834 26.342c3.227-.011 26.079-.023 29.306.006.6.005.8-.131.793-.762a1.764 1.764 0 0 1 1.775-1.778q2.026-.051 4.053.011a.814.814 0 0 1 .623.463c.061.394-.285.513-.649.511h-3.752a1 1 0 0 0-1.088.8h4.795c.349 0 .694.054.708.475.016.467-.353.522-.721.523h-4.235c-.3 0-.563-.008-.566.422s.25.434.556.433h4.356c.341 0 .67.137.591.5a.818.818 0 0 1-.617.471c-1.37.044-2.742.024-4.113.024h-.656c.009.645.282.9.959.911 1.109.013 2.219 0 3.328 0 .161 0 .323.005.484 0 .34-.009.677.1.623.468a.828.828 0 0 1-.6.49 14.511 14.511 0 0 1-1.752.025c-.726 0-1.453.014-2.178 0a1.822 1.822 0 0 1-1.941-1.991c.013-.58-.18-.7-.72-.7-3.207.023-26.154.227-29.36.227"
                    fill="#fff"
                  />
                </g>
                <g data-name="Group 15">
                  <path
                    data-name="Path 33"
                    d="M2.273 43.183c0 1.529-.067 3.076.06 4.6a17.67 17.67 0 0 0 35.281-1.488c.012-3.062 0-6.125 0-9.188a.74.74 0 0 0-1.48 0v8.257a20.064 20.064 0 0 1-.454 4.8 16.107 16.107 0 0 1-3.188 6.413 16.2 16.2 0 0 1-28.629-8.291 44.518 44.518 0 0 1-.111-5.107.74.74 0 0 0-1.48 0Z"
                    fill="#fff"
                  />
                </g>
                <g data-name="Group 16">
                  <path
                    data-name="Path 34"
                    d="M37.564 37.658c-3.227.01-26.079.022-29.3-.006-.6-.005-.8.131-.793.763a1.765 1.765 0 0 1-1.778 1.775q-2.025.051-4.052-.011a.814.814 0 0 1-.623-.463c-.061-.394.284-.513.649-.512h3.751a1 1 0 0 0 1.088-.8H1.711c-.349 0-.694-.055-.708-.475-.015-.467.353-.522.721-.523h4.235c.3 0 .564.008.566-.421s-.251-.435-.556-.434q-1.937.005-3.872 0h-.484c-.341 0-.671-.137-.591-.5a.82.82 0 0 1 .617-.471c1.37-.044 2.742-.023 4.113-.024h.655c-.009-.645-.282-.9-.958-.912-1.109-.014-2.219 0-3.328 0-.162 0-.323-.005-.484 0-.34.009-.677-.105-.622-.468a.828.828 0 0 1 .6-.49 14.5 14.5 0 0 1 1.752-.025c.726 0 1.452-.015 2.178 0a1.822 1.822 0 0 1 1.941 1.991c-.013.579.18.7.72.7 3.206-.023 26.153-.227 29.359-.227"
                    fill="#fff"
                  />
                </g>
              </g>
              <g data-name="Group 80">
                <g data-name="Group 13">
                  <path
                    data-name="Path 31"
                    d="M3.265 26.888v-8.256a20.091 20.091 0 0 1 .453-4.8 16.117 16.117 0 0 1 3.188-6.414 16.2 16.2 0 0 1 28.629 8.292 44.539 44.539 0 0 1 .111 5.107.74.74 0 0 0 1.48 0c0-1.528.068-3.077-.06-4.6a17.67 17.67 0 0 0-35.281 1.488c-.013 3.062 0 6.125 0 9.187a.74.74 0 0 0 1.48 0Z"
                    fill="#fff"
                  />
                </g>
                <g data-name="Group 78">
                  <path
                    data-name="Path 35"
                    d="m33.515 19.466 2.344 2.344a.583.583 0 0 0 .812 0l2.367-2.368a.574.574 0 0 0-.811-.811l-2.368 2.368h.812l-2.345-2.345a.574.574 0 0 0-.811.812Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g data-name="Group 79">
              <path
                data-name="Path 36"
                d="m5.693 44.428-2.345-2.345a.583.583 0 0 0-.811 0L.169 44.451a.574.574 0 0 0 .811.811l2.368-2.367h-.811l2.345 2.344a.574.574 0 0 0 .811-.811Z"
                fill="#fff"
              />
            </g>
          </g>
        </LogoMob>

      </NavLink>

      <Burger open={open} setOpen={setOpen} />

      <Menu open={open} setOpen={setOpen} cart={cart}/>
    </HeaderContainer>
  );
};

export default Header;
